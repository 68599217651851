@import '../../Vendor/style/base/base';

body{
  position: relative;
}
.body-wrap{
  position: static;
}
.content-wrap_form{
  .footer-wrap{
    position: absolute;
    bottom:0;
    left:0;
    width:100%;
    background-color: $white;
  }
}
.body-formWrap{
  padding-bottom:406px;
  .content-toknow{
    max-width: 300px;
  }
}

@media only screen and (min-width: 992px) {
  .body-formWrap{
    padding-bottom:239px;
    .content-contactCol6{
      padding-left:30px;
    }
  }
}