//bem_element
@mixin e ($name) {
  &-#{$name} {
    @content;
  }
}
//bem-modifier
@mixin m ($name) {
  &_#{$name} {
    @content;
  }
}
//blockElementModifier