@import '../../Vendor/style/base/base';

.ShareList {
  @include e (social){
    margin:0 -5px 30px;
  }
  @include e (socialIcon){
    display: inline-block;
    width:34px;
    height: 34px;
    margin:0 5px;
    padding:2px;
    @include m (desktop){
      display: none;
    }
  }
}

@media only screen and (min-width: 992px) {
  .ShareList {
    @include e (socialIcon){
      @include m (mobile){
        display: none;
      }
      @include m (desktop){
        display: inline-block;
      }
    }
  }
}