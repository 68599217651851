@import '../../Vendor/style/base/base';

.product{
	@include e (post){
		margin-bottom:20px;
		border: 1px solid #E9E9E9;
	}
	@include e (postBorder){
		width:100%;
		padding:20px;
	}
	@include e (postPhoto){
		width:100%;
		height: 0;
		padding-bottom:120.833333%;
		margin-bottom:20px;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}
	@include e (postTitle){
		font-size: 1.5rem;
		font-weight: bold;
		letter-spacing: 3px;
		line-height: 26px;
		margin:0 0 20px;
	}
	@include e (postText){
		margin:0;
		p{
			font-size: 1.2rem;
			letter-spacing: 2.4px;
			line-height: 26px;
			margin:0;
		}
	}
}

@media only screen and (min-width: 768px) {
	.product{
		@include e (postWrap){
			display: flex;
			flex-wrap: wrap;
			margin:0 -25px 22px;
		}
		@include e (post){
			width:calc(50% - 30px);
			margin:0 15px 58px;
		}
	}
	.ant-pagination {
		margin-bottom:80px;
	}
}

@media only screen and (min-width: 992px) {
	.product{
		@include e (post){
			width:calc(33.333333% - 30px);
		}
	}
}