@import '../../Vendor/style/base/base';
/*=====  header  ===== */
.header{
	@include e (home){
		&.j-fixedMenu{
			.header-nvalink1.font_pink{
				color:$pink!important;
			}
			.header-logoInfo{
				display: none!important;
			}
		}
	}
	@include e (wrap){
		position: absolute;
		display: block;
		top:0;
		left: 0;
		width:100%;
		background-color: rgba(255,255,255,0.85);
		box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
		z-index: $z_Header;
		transition: top 1s;
		&.j-light{
			background-color: rgba(256,256,256,0.01);
			box-shadow: none;
			.header-iconPath{
				fill:$white;
			}
		}
		&.j-fixedMenu{
			position: fixed;
			background-color: rgba(255,255,255,0.85)!important;
			box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2)!important;
			.header-iconPath{
				fill:$black1!important;
			}
			.header-nvalink1.font_pink{
				color:$pink!important;
			}
			.header-nvalink2{
				@include link-hover{
					.header-iconPath{
						fill:$pink!important;
					}
				}
			}
		}
	}
	@include e (container){
		display: flex;
		justify-content:space-between;
		align-items:center;
		height: 60px;
		padding:0 20px;
		width:100%;
	}
	@include e (logoWrap){
		display: flex;
		justify-content:space-between;
		align-items:center;
		height: 30px;
		text-decoration: none;
		width:calc(100% - 45px);
	}
	@include e (logoIcon){
		margin-right:15px;
		width:30px;
		height: 30px;
	}
	@include e (iconPath){
		fill:$black1;
	}
	@include e (logoInfo){
		line-height: 0;
		width: 220px;
		height: 25px;
	}
	@include e (nva1){
		display: none;
	}
	@include e (nva2){
		display: none;
	}
	@include e (hamburg){
		display: block;
		width:30px;
		height: 30px;
		@include link-hover{
			.header-iconPath{
				fill:$pink;
			}
		}
	}
}
@media only screen and (min-width: 400px) {
	.header{
		@include e (logoWrap){
			width:auto;
		}
		@include e (logoIcon){
			margin-right:27px;
		}
	}
}

@media only screen and (min-width: 992px) {
	/*=====  header  ===== */
	.header{
		@include e (home){
			.header-logoInfo{
				display: block;
			}
		}
		@include e (wrap){
			&.j-light{
				.header-nvalink1{
					color:$white;
				}
			}
			&.j-fixedMenu{
				.header-logoIcon{
					width:34px!important;
					height: 34px!important;
				}
				.header-nvalink1{
					color:$black1;
				}
				.header-container{
					height: 80px!important;
				}
				.header-nvaWrap{
					margin-top:23px!important;
				}
				.header-nva2{
					top:-25px!important;
				}
			}
		}
		@include e (container){
			height: 80px;
			padding: 0 40px;
			&.j-higher{
				height: 150px;
				.header-logoIcon{
					width:56px;
					height: 56px;
				}
				.header-nvaWrap{
					margin:0;
				}
				.header-nva2{
					top:-30px;
				}
			}
		}
		@include e (logoIcon){
			width: 34px;
			height: 34px;
		}
		@include e (logoInfo){
			display: none;
		}
		@include e (nvaWrap){
			position: relative;
			display: inline-flex;
			width:calc(100% - 100px);
			justify-content:flex-end;
			align-items:center;
			margin-top:20px;
		}
		@include e (nva1){
			width:calc(100% - 50px);
			display: inline-flex;
			list-style: none;
			justify-content:flex-end;
			align-items:center;
			padding:0 30px 0 0;
			margin:0;
			li:not(:last-child){
				margin-right:15px;
			}
		}
		@include e (nvalink1){
      font-family: "Noto Serif CJK TC";
			color:$black1;
			font-size: 1.4rem;
			letter-spacing: 4px;
			line-height: 20px;
			text-decoration: none;
			@include link-hover{
				color:$pink!important;
			}
		}
		@include e (nva2){
			position: absolute;
			display: inline-flex;
			list-style: none;
			justify-content:space-between;
			align-items:center;
			padding:0;
			margin:0;
			top:-25px;
			right:0;
		}
		@include e (nvalink2){
      font-family: "Noto Serif CJK TC";
			display: block;
			width:20px;
			height: 20px;
			margin-left:25px;
			text-decoration: none;
			@include m (search){
				padding:2px;
			}
			@include link-hover{
				.header-iconPath{
					fill:$pink;
				}
			}
		}
	}
}


@media only screen and (min-width: 1200px) {
	.header{
		@include e (nva1){
			li:not(:last-child){
				margin-right:25px;
			}
		}
	}
}