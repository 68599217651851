@import '../../Vendor/style/base/base';

.news{
	@include e (wrap){
    .ant-pagination{
      padding:20px 0 40px;
    }
	}
	@include e (box){
		margin-bottom:40px;
		opacity: 0;
	}
	@include e (thumbnail){
		display: block;
		width:100%;
		margin-bottom:15px;
	}
	@include e (photo){
		width:100%;
		height: 0;
		padding-bottom:57%;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}
	@include e (time){
		font-size: .9rem;
		letter-spacing: 2.7px;
		line-height: 13px;
		margin:0 0 10px;
	}
	@include e (boxTitle){
		font-size: 1.3rem;
		font-weight: bold;
		letter-spacing: 2.6px;
		line-height: 26px;
		margin:0 0 14px;
	}
	@include e (summary){
		p{
			font-size: 1.2rem;
			letter-spacing: 2.4px;
			line-height: 26px;
			margin:0;
		}
	}
}

@media only screen and (min-width: 768px) {
	.news{
    @include e (wrap){
      .ant-pagination{
        padding:40px 0 80px;
      }
    }
		@include e (postWrap){
			display: flex;
			flex-wrap:wrap;
			justify-content:flex-start;
		}
		@include e (box){
      width:30%;
      &:not(:nth-child(3n)){
        margin-right: 5%;
      }
		}
		@include e (time){
			font-size: 1rem;
			letter-spacing: 3px;
			line-height: 15px;
		}
		@include e (boxTitle){
			font-size: 1.5rem;
			font-weight: bold;
			letter-spacing: 3px;
			margin:0 0 20px;
		}
	}
}