.timeLine{
  position: relative;
  max-width: 600px;
  width:100%;
  margin:0 auto 18px;
  padding-bottom:25px;
  text-align: left;
  &::before{
    display: block;
    position: absolute;
    content:'';
    bottom:0px;
    left:6.5px;
    height: calc(100% - 64px);
    width: 0;
    border: 2px solid #CCD5DC;
  }
  &::after{
    display: block;
    position: absolute;
    content:'';
    bottom:0px;
    left:-2px;
    height: 0;
    width: 17px;
    border: 2px solid #CCD5DC;
  }
  h1{
      font-family: "Noto Serif CJK TC";
      font-size: 3rem;
      font-weight: bold;
      letter-spacing: 4.69px;
      line-height: 44px;
      margin:0;
      padding-bottom: 20px;
      &::before{
        display: block;
        position: absolute;
        content:'';
        top:57px;
        left:-2px;
        height: 0;
        width: 17px;
        border: 2px solid #CCD5DC;
      }
  }
  p{
    position: relative;
    font-family: "Noto Serif CJK TC";
    font-size: 1.5rem;
    letter-spacing: 2px;
    line-height: 30px;
    padding-left:40px;
    margin:25px 0 0;
    &::after{
      display: block;
      position: absolute;
      content:'';
      top: 4px;
      left: -4px;
      width:14px;
      height: 14px;
      background-color: $pink;
      border-radius: 100%;
      border:5px solid $white;
    }
    @include link-hover{
      &::before{
        background-color: $white;
      }
    }
  }
}