@import '../../Vendor/style/base/base';

.member{
	@include e (wrap){
		padding:30px 0 5px;
	}
	@include e (content){
		padding-top:30px;
	}
	@include e (title){
		font-size: 1.5rem;
		font-weight: bold;
		letter-spacing: 3px;
		line-height: 26px;
		margin:0;
	}
	@include e (time){
		color: #999999;
		font-size: .9rem;
		letter-spacing: 1.8px;
		line-height: 26px;
		margin:0;
	}
	@include e (row){
		display:flex;
		justify-content:center;
		flex-wrap:wrap;
	}
	@include e (col){
		width:100%;
		margin-top:25px;
		text-align: center;
		opacity: 0;
	}
	@include e (photo){
		width:200px;
		height: 200px;
		border-radius: 100%;
		margin:0 auto 15px;
	}
	@include e (colTitle){
		font-size: 15px;
		font-weight: bold;
		letter-spacing: 3px;
		line-height: 26px;
	}
	@include e (colInfo){
		font-size: 12px;
		letter-spacing: 2.4px;
		line-height: 16px;
		max-width: 200px;
		margin:12px auto;
		span{
			display: inline-block;
			margin-bottom:10px;
		}
	}
	@include e (select){
		width:100%;
		max-width: 280px;
		.ant-select{
			width:100%;
		}
	}
}

@media only screen and (min-width: 768px) {
	.member{
		@include e (col){
			width:45%;
		}
	}
}

@media only screen and (min-width: 992px) {
	.member{
		@include e (wrap){
			padding:30px 0 80px;
		}
		@include e (time){
			padding-bottom:25px;
		}
		@include e (col){
			margin-top:50px;
			width:30%;
		}
	}
}