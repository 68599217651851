@charset "UTF-8";
.Gallery-wrap {
  padding: 0 20px; }
  .Gallery-wrap .ant-pagination {
    padding: 20px 0 40px; }

.Gallery-header {
  padding-bottom: 20px; }

.Gallery-row1 {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.Gallery-ctl {
  display: flex;
  margin-bottom: 20px; }

.Gallery-ctlIcon {
  display: inline-block;
  width: 25px;
  height: 25px; }

.Gallery-ctlInfo {
  display: none; }

.Gallery-ctlLink {
  display: flex;
  color: #999;
  text-decoration: none; }
  .Gallery-ctlLink:hover, .Gallery-ctlLink:focus, .Gallery-ctlLink:active, .Gallery-ctlLink.active {
    color: #E40080; }
    .Gallery-ctlLink:hover .Gallery-svg, .Gallery-ctlLink:focus .Gallery-svg, .Gallery-ctlLink:active .Gallery-svg, .Gallery-ctlLink.active .Gallery-svg {
      fill: #E40080; }
  .Gallery-ctlLink:not(:first-child) {
    margin-left: 15px; }

.Gallery-svg {
  fill: #999; }

.Gallery-search {
  margin-bottom: 15px; }
  .Gallery-search .anticon-search {
    color: '#323237';
    font-size: 18px;
    margin-left: -5px; }

.Gallery-select {
  display: inline-block;
  width: 90px;
  margin: 0 10px 15px 0; }
  .Gallery-select .ant-select {
    width: 100%; }

.Gallery-option .ant-select-dropdown-menu-item {
  white-space: normal;
  text-overflow: initial;
  word-break: break-all; }

.Gallery-boxWrap {
  display: none;
  margin-bottom: 40px; }
  .Gallery-boxWrap.active {
    display: block; }
  .Gallery-boxWrap iframe {
    width: 100%;
    height: 60VW; }

.Gallery-box {
  position: relative;
  margin-bottom: 20px;
  opacity: 0; }

.Gallery-photo {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 160.71%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }
  .Gallery-photo::after {
    display: block;
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(50, 50, 55, 0.3); }

.Gallery-boxInfo {
  position: absolute;
  left: 0;
  bottom: 30px;
  width: 100%;
  padding: 0 20px;
  color: #fff; }

.Gallery-boxTitle {
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 3px;
  line-height: 26px;
  margin: 0; }

.Gallery-boxSubTitle {
  font-family: "Noto Sans CJK TC", "微軟正黑體", sans-serif;
  font-size: 12px;
  letter-spacing: 2.4px;
  line-height: 26px;
  font-weight: normal;
  margin: 0; }

.ant-pagination {
  margin-bottom: 40px; }

@media only screen and (min-width: 768px) {
  .Gallery-wrap {
    position: relative; }
    .Gallery-wrap .ant-pagination {
      padding: 40px 0 80px; }
  .Gallery-ctlInfo {
    display: inline;
    margin-left: 10px;
    font-size: .9rem;
    letter-spacing: 1.8px;
    line-height: 25px; }
  .Gallery-ctlLink:not(:first-child) {
    margin-left: 25px; }
  .Gallery-row2 {
    display: flex;
    justify-content: space-between; }
  .Gallery-search {
    width: 280px; }
  .Gallery-selectWrap {
    width: calc(100% - 280px);
    display: flex;
    justify-content: flex-end; }
  .Gallery-select {
    margin: 0 0 15px 10px; }
  .Gallery-wrap {
    padding: 25px 40px; }
  .Gallery-boxWrap {
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0 -15px 80px; }
    .Gallery-boxWrap.active {
      display: flex; }
  .Gallery-box {
    width: 50%;
    margin-bottom: 30px;
    padding: 0 15px; }
  .Gallery-boxInfo {
    padding: 0 35px; }
  .ant-pagination {
    margin-bottom: 80px; } }

@media only screen and (min-width: 992px) {
  .Gallery-box {
    width: 33.333333%; } }
