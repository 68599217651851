@import '../../Vendor/style/base/base';

.project{
	@include e (tabWrap){
		padding:30px 0;
	}
	@include e (loadMore){
		.link_loadMore{
			margin: 60px auto;
		}
	}
}

@media only screen and (min-width: 768px) {
	.project{
		@include e (loadMore){
			.link_loadMore{
				margin: -25px auto 80px;
			}
		}
	}
}

@media only screen and (min-width: 992px) {
	.project{
		@include e (tabWrap){
			padding-top:60px;
		}
		@include e (loadMore){
			.link_loadMore{
				margin: -60px auto 80px;
			}
		}
	}
}