@import '../../Vendor/style/base/base';
.hero{
	@include e (wrap){
		position: relative;
		margin-top:-60px;
		width:100vw;
		height: 100vh;
		min-height: 400px;
		.slick-slide{
			pointer-events: none;
			&.slick-active{
				pointer-events: auto;
			}
		}
	}
	@include e (slick){
		width:100vw;
		height: 100vh;
		min-height: 400px;
		overflow: hidden;
		.slick-dots{
			position: absolute;
			top:calc(50% - 20px);
			width:auto;
			right:13px;
			padding:0;
			margin:0;
			list-style: none;
			button{
				color:transparent;
				border:0;
				background-color: transparent;
			}
			li{
				display: list-item;
				button::before{
					position: absolute;
					display: block;
					content:'';
					width:5px;
					height: 5px;
					top:7px;
					left:7px;
					border-radius: 9px;
					border:1px solid $white;
					cursor: pointer;
					opacity: 1;
				}
				&.slick-active button::before{
					background-color: $white;
				}
			}
		}
	}
	@include e (photoWrap){
		position: relative;
		width:100vw!important;
		height: 100vh!important;
		min-height: 400px;
		.layout-container{
			display: flex!important;
			justify-content:flex-end;
			align-items:center;
			width:100%;
			height: 100%;
			z-index: 1;
		}
	}
	@include e (photo){
		position: absolute;
		top:0;
		left:0;
		width:100vw!important;
		height: 100vh!important;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		z-index: -1;
		&::before{
			position: absolute;
			content: '';
			display:block;
			width:100%;
			height: 100%;
			background-color: rgba(0,0,0,0.15);
			z-index: 1;
			pointer-events:none;
		}
	}
	@include e (logo){
		position: absolute;
		top:calc(50% - 57px);
		left:calc(50% - 32px);
		width:64px;
		height: 114px;
		z-index: 1;
	}
	@include e (abridge){
		position: absolute;
		top: calc(50% - 7px);
		left:0;
		color:$white;
		margin:0;
		transform: rotate(90deg) scale(0.83);
		font-family: "Noto Serif CJK TC";
		font-size: 1.2rem;
		letter-spacing: 7px;
		line-height: 13px;
		z-index: 1;
	}
	@include e (infoWrap){
		writing-mode: vertical-rl;
		-ms-writing-mode: tb-rl;
		display: block;
		font-weight: bold;
		padding:0 40px 0 0;
	}
	@include e (info){
		font-family: "Noto Serif CJK TC";
		letter-spacing: 8px;
		color:$white;
		margin:0;
		p{
			margin:0;
		}
	}
	@include e (more){
		font-size: 1.2rem;
		letter-spacing: 2.7px;
	}
	@include e (scroll){
		position: absolute;
		bottom:45px;
		left:calc(50% - 24px);
		font-size: .9rem;
		letter-spacing: 2.7px;
		line-height: 13px;
		text-decoration: none;
		&::before{
			content:'';
			display: block;
			position: absolute;
			height: 30px;
			width:1px;
			top:28px;
			left:23px;
			background-color: $white;
		}
		&::after{
			content:'';
			display: block;
			position: absolute;
			height: 30px;
			width:1px;
			top:58px;
			left:23px;
			background-color: #cecece;
		}
	}
}

@media only screen and (min-width: 992px) {
	.hero{
		@include e (wrap){
			margin-top:-80px;
		}
		@include e (slick){
			.slick-dots{
				right:26px;
			}
		}
		@include e (photo){
			background-attachment: fixed;
		}
		@include e (infoWrap){
			padding:0 140px 0 0;
		}
		@include e (infoWrap){
			font-weight: normal;
		}
		@include e (info){
			font-size: 1.4rem;
			line-height: 3;
		}
		@include e (more){
			line-height: 3;
		}
	}
}

@media only screen and (min-width: 1200px) {
	.hero{
		@include e (infoWrap){
			padding:0 150px 0 0;
		}
	}
}
_:-ms-lang(x), 
_::-webkit-meter-bar, 
.hero-photo {
	background-attachment: scroll;
}