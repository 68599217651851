@import '../../Vendor/style/base/base';

.LightBox {
  @include e (imgWrap){
    img{
      cursor: pointer;
    }
  }
  @include e (wrap){
    position: fixed;
    display: block;
    width:100vw;
    height: 100vh;
    top:0;
    left:0;
    background-color: rgba(0, 0, 0, .8);
    z-index: 999999;
    opacity: 0;
    pointer-events: none;
    transition: opacity .5s;
    overflow: scroll;
    &.active{
      opacity: 1;
      pointer-events: auto;
    }
  }
  @include e (content){
    position: relative;
    width:100%;
    height: 100%;
    padding: 60px 35px 0;
    max-width: 800px;
    margin:0 auto;
    .slick-prev{
      left: -35px;
      width: 32px;
      height: 32px;
      &::before{
        font-family: anticon!important;
        font-size: 3.2rem;
        content: "\E620";
        opacity: 1;
      }
    }
    .slick-next{
      right: -35px;
      width: 32px;
      height: 32px;
      &::before{
        font-family: anticon!important;
        font-size: 3.2rem;
        content: "\E61F";
        opacity: 1;
      }
    }
  }
  @include e (photo){
    padding:5px;
  }
  @include e (img){
    height: 100%;
    max-height: 50vh;
    width: auto;
    max-width: none;
    margin: 0 auto;
    border:5px solid $white;
  }
  @include e (info){
    font-size: 12px;
    letter-spacing: 2.4px;
    line-height: 26px;
    color:$white;
  }
  @include e (thumbWrap){
    display: flex;
    padding: 0 5px;
    li{
      display: inline-block;
      margin-right:10px;
    }
  }
  @include e (thumb){
    width:45px;
    height: 45px;
    cursor: pointer;
    background-size: cover;
  }
  @include e (close){
    position: absolute;
    display: block;
    right:3px;
    top:13px;
    width: 40px;
    height: 40px;
    .anticon{
      font-size: 3.2rem;
      color:$white;
    }
  }
}

@media only screen and (min-width: 992px) {
  .LightBox {
    @include e (content){
      padding: 80px 35px 0;
      .slick-prev{
        left: -70px;
        width: 40px;
        height: 40px;
        &::before{
          font-size: 4rem;
        }
      }
      .slick-next{
        right: -70px;
        width: 40px;
        height: 40px;
        &::before{
          font-size: 4rem;
        }
      }
    }
    @include e (close){
      right:-64px;
      top:13px;
      width: 40px;
      height: 40px;
      .anticon{
        font-size: 4rem;
        color:$white;
      }
    }
  }
}