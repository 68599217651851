@import '../../Vendor/style/base/base';

.event{
	@include e (content){
		position: relative;
	}
	@include e (last){
		border: 1px solid #E9E9E9;
		margin-bottom:19px;
		.content-photo{
			position: relative;
			margin:0;
		}
		.content-heroTitle{
			font-size: 16px;
			letter-spacing: 2.4px;
			line-height: 23px;
			bottom:10px;
			margin:0;
		}
	}
	@include e (info){
		padding:25px 20px;
	}
	@include e (select){
		width:100%;
		max-width: 280px;
		margin-top: 25px;
		letter-spacing: 1px;
	}
	@include e (loadMore){
		padding-top:20px;
		.link_loadMore{
			margin: 60px auto;
		}
	}
}

@media only screen and (min-width: 768px) {
	.event{
		@include e (lastWrap){
			display:flex;
			flex-wrap: wrap;
			justify-content:space-between;
		}
		@include e (last){
			width:calc(50% - 20px);
			margin-bottom:39px;
			.content-heroTitle{
				bottom:20px;
			}
			@include m (wider){
				width:100%;
				display:flex;
				.content-photo{
					width:60%;
					padding-bottom: 34.2%;
				}
				.content-heroTitle{
					bottom:40px;
				}
				.event-info{
					display:flex;
					flex-wrap:wrap;
					align-content:space-between;
					width:40%;
				}
				.content-toknow{
					width:280px;
				}
			}
		}
		@include e (selectBr){
			display: none;
		}
		@include e (select){
			margin:0 35px;
		}
		@include e (loadMore){
			.link_loadMore{
				margin: -25px auto 80px;
			}
		}
	}
}

@media only screen and (min-width: 992px) {
	.event{
		@include e (tabWrap){
			padding-top:60px;
		}
		@include e (loadMore){
			.link_loadMore{
				margin: -60px auto 80px;
			}
		}
	}
}