@import '../../Vendor/style/base/base';

.eventPost{
	@include e (infoWrap){
		margin-bottom:30px;
	}
	@include e (file){
		text-align: center;
		.link_file{
			display: block;
			margin-bottom:15px;
		}
	}
}

@media only screen and (min-width: 992px) {
	.eventPost{
		@include e (infoWrap){
			margin-bottom:60px;
		}
	}
}