@import '../../Vendor/style/base/base';
/*=====  footer  ===== */
.footer{
	@include e (row){
		padding:30px 15px;
	}
	@include e (col){
		margin-bottom:25px;
	}
	@include e (title){
		font-family: "Noto Serif CJK TC";
		font-size: 1.4rem;
		font-weight: bold;
		letter-spacing: 2.8px;
		line-height: 26px;
		margin:0;
	}
	@include e (info){
		font-size: .9rem;
		letter-spacing: 1.8px;
		line-height: 1.4;
		margin:0 0 10px;
	}
	@include e (list){
		margin:0 0 25px;
		padding:0;
		list-style: none;
		opacity: 0.6;
		font-size: 1rem;
		letter-spacing: 1.82px;
		line-height: 1.8;
		li{
			display:flex
		}
	}
	@include e (list1){
		display: inline-block;
		width:60px;
		margin-right:5px;
		flex: 0 0 60px;
	}
	@include e (list2){
		display: inline-block;
		margin-right:9px;
	}
	@include e (cr){
		opacity: 0.6;
		font-size: 1.2rem;
		letter-spacing: 1.82px;
		line-height: 1.8;
		margin:0;
	}
	@include e (btnWrap){
		.btn{
			margin-right:10px;
		}
	}
}

@media only screen and (min-width: 992px) {
	/*=====  footer  ===== */
	.footer{
		@include e (row){
			display: flex;
			justify-content:space-between;
			padding:60px 40px 40px;
		}
		@include e (col){
			width:30%;
		}
		@include e (info){
			margin:0 0 20px;
		}
	}
}