.tab{
  @include e (ctlWrap){
    display: flex;
    flex-wrap:wrap;
    margin-bottom:15px;
    width:100%;
  }
  @include e (link){
    display: inline-flex;
    align-items:center;
    justify-content:center;
    width:calc(50% - 8px);
    height: 35px;
    margin-bottom:15px;
    color:$black1;
    font-size: 1.4rem;
    letter-spacing: 2.8px;
    border:1px solid $black1;
    text-decoration: none;
    &:nth-child(odd){
      margin-right:16px;
    }
    @include link-hover{
      color:$white;
      border-color: $pink;
      background-color: $pink;
    }
  }
  @include e (title){
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 10px;
    line-height: 35px;
    margin: 0 0 30px;
    text-align: center;
  }
  @include e (content){
    width:100%;
    max-width: 550px;
    margin:0 auto 30px;
    @include m (full){
      max-width: none;
    }
  }
}

@media only screen and (min-width: 768px) {
  .tab{
    @include e (link){
      width:calc(33% - 8.5px);
      &:nth-child(3n+0){
        margin-right:0;
      }
      &:not(:nth-child(3n+0)){
        margin-right:16px;
      }
      &:last-child{
        margin-right:0;
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .tab{
    @include e (ctlWrap){
      justify-content:center;
    }
    @include e (link){
      width:160px;
      &:nth-child(n){
        margin-right:15px;
      }
      &:last-child{
        margin-right:0;
      }
    }
    @include e (title){
      margin: 0 0 60px;
    }
    @include e (content){
      padding:30px 0 0;
    }
  }
}