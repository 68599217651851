
.select-hidden {
  display: none;
  visibility: hidden;
  padding-right: 10px;
}
.select {
  cursor: pointer;
  display: inline-block;
  position: relative;
  font-size: 1.4rem;
  color: $black1;
  width:100%;
  max-width: 280px;
  height: 35px;
}
.select-styled {
  position: absolute; 
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 5px 15px;
  border:1px solid $black1;
  &:after {
    content:"";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 6px 0 6px;
    border-color: $black1 transparent transparent transparent;
    position: absolute;
    top: 13px;
    right: 10px;
  }
  &:active, &.active {
    &:after {
      border-width: 0 6px 10px 6px;
      border-color: transparent transparent $black1 transparent;
    }
  }
}

.select-options {
  display: none; 
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  z-index: 999;
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    margin: 0;
    padding: 5px 15px;
    background-color: $white;
    border:1px solid $black1;
    border-top:0;
    &:hover {
      color: $white;
      background: $pink;
    }
    &[rel="hide"] {
      display: none;
    }
  }
}