@import "./fading_entrances/fadeIn";
@import "./fading_entrances/fadeInUp";
@import "./fading_entrances/fadeInRight";

.animated {
  animation-duration: 1.5s;
  animation-fill-mode: both;
}

.animated_75 {
  animation-duration: .75s;
  animation-fill-mode: both;
}

.animated.infinite {
  animation-iteration-count: infinite;
}

// check hook animate
@-webkit-keyframes dashCheck {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes dashCheck {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}