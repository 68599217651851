@import '../../Vendor/style/base/base';

.galleryPost{
	@include e (infoRow){
		display: flex;
	}
	@include e (info){
		margin-bottom:20px;
	}
	@include e (infoTitle){
		width:40%;
		padding-right:20px;
		font-size: 15px;
		font-weight: bold;
		letter-spacing: 3px;
		line-height: 26px;
		margin:0;
	}
	@include e (infoText){
		width:60%;
		font-size: 12px;
		letter-spacing: 2.4px;
		line-height: 26px;
		margin:0;
	}
	@include e (map){
		iframe{
			width:100%;
			height: 50vw;
		}
	}
}
.content-article{
	margin-bottom:30px;
}
.slick-dots {
	z-index: 1;
}

@media only screen and (min-width: 768px) {
	.galleryPost{
		@include e (infoWrap){
			padding:60px 0 30px;
			display: flex;
		}
		@include e (info){
			width:50%;
		}
		@include e (map){
			width:50%;
			iframe{
				height: 25vw;
			}
		}
	}
	.content-article{
		margin-bottom:60px;
	}
	.content-socialWrap{
		margin-bottom:30px;
	}
}