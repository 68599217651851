//bem_element
@function rem($px){ $rem : 12; @return ($px/$rem) + rem; }

@mixin prefix($property, $value) {
  -webkit-#{$property}: #{$value};
  -moz-#{$property}: #{$value};
  -ms-#{$property}: #{$value};
  -o-#{$property}: #{$value};
  #{$property}: #{$value};
}

@mixin transition($value) {
  @include prefix("transition", $value);
}

@mixin transform($value) {
  @include prefix("transform", $value);
}

@mixin rotate($degrees) {
  @include transform($degrees);
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(sizingMethod='auto expand', M11=#{cos($degrees)}, M12=#{-1 * sin($degrees)}, M21=#{sin($degrees)}, M22=#{cos($degrees)})";
  filter: "progid:DXImageTransform.Microsoft.Matrix(sizingMethod='auto expand', M11=#{cos($degrees)}, M12=#{-1 * sin($degrees)}, M21=#{sin($degrees)}, M22=#{cos($degrees)})";
}

@mixin border-radius($radius) {
  @include prefix("border-radius", $radius);
}

@mixin box-shadow($shadow) {
  @include prefix("box-shadow", $shadow);
}

@mixin box-sizing($value) {
  @include prefix("box-sizing", $value);
}

@mixin background-size($size) {
  @include prefix("background-size", $size);
}

@mixin link {
  &:link,
  &:visited {
    @content;
  }
}

@mixin link-hover {
  &:hover, &:focus, &:active, &.active {
    @content;
  }
}

@mixin link-all{
  &:hover, &:focus, &:link, &:visited {
    @content;
  }
}

@mixin clr($clr, $bgclr) {
  background: $bgclr;
  color: $clr;
}

@mixin cf {
  zoom: 1;
  &:before,
  &:after {
    content: ".";
    display: block;
    height: 0;
    overflow: hidden;
  }
  &:after {
    clear: both;
  }
}

@mixin placeholder ($fontcolor) {
  &::-webkit-input-placeholder {
      color: $fontcolor;
  }
  &:-moz-placeholder {
      /* FF 4-18 */
      color: $fontcolor;
  }
  &::-moz-placeholder {
      /* FF 19+ */
      color: $fontcolor;
  }
  &:-ms-input-placeholder {
      /* IE 10+ */
      color: $fontcolor;
  }
}