@import '../../Vendor/style/base/base';

.productPost{
	@include e (infoRow){
		.content-toknow{
			width:100%;
			max-width:300px;
			margin:0 0 25px;
		}
	}
	@include e (infoTitle){
		font-size: 20px;
		font-weight: 500;
		letter-spacing: 8.33px;
		line-height: 29px;
		margin:0 0 31px;
	}
	@include e (photoWrap){
		margin-bottom:67px;
		.slick-list{
			border: 1px solid #E9E9E9;
		}
		.slick-dots{
			bottom:-40px;
			left:0;
		}
	}
	@include e (heroPhoto){
		width:100%;
		height: 0;
		padding-bottom:120%;
		background-position: center;
		background-repeat: no-repeat;
		background-size:  90%;
	}
	@include e (infoTable){
		margin-bottom:40px;
		table{
			border-collapse: collapse!important;
			border: 0!important;
			border-spacing: 0!important;
			tr{
				height: auto!important;
				line-height: 26px!important;
			}
			td{
				height: auto!important;
				border: 0!important;
				line-height: 26px!important;
				&:first-child{
					font-family: "Noto Serif CJK TC";
					width:30%!important;
					font-size: 15px!important;
					font-weight: bold!important;
					letter-spacing: 3px!important;
					line-height: 26px!important;
					margin:0!important;
					h1,h2,h3,h4,h5,h6,p{
						font-family: "Noto Serif CJK TC";
						font-size: 15px!important;
						font-weight: bold;
						letter-spacing: 3px;
						line-height: 26px!important;
						margin:0!important;
					}
				}
				&:last-child{
					width:70%!important;
					font-size: 12px!important;
					letter-spacing: 2.4px!important;
					line-height: 26px!important;
					margin:0!important;
					h1,h2,h3,h4,h5,h6,p{
						font-size: 12px!important;
						letter-spacing: 2.4px;
						line-height: 26px!important;
						margin:0!important;
					}
				}
				p{
					margin:0!important;
				}
			}
		}
	}
}
.content-article{
	margin-bottom:30px;
}

@media only screen and (min-width: 768px) {
	.productPost{
		@include e (infoRow){
			display: flex;
			padding-bottom:30px;
			margin-bottom:30px;
			border-bottom: 1px solid #E9E9E9;
		}
		@include e (photoWrap){
			width: 40%;
			height: 40%;
			margin-bottom:0;
			.slick-dots{
				position: static;
				display: flex;
				justify-content:flex-start;
				text-align: left;
				width:calc(100% + 25px);
				margin:0 -12.5px;
				padding-top:24px;
				li{
					width:33.333333%;
					height: auto;
					padding:0 12.5px;
					margin: 0 0 25px;
				}
			}
		}
		@include e (heroCtl){
			width:100%;
			height: 0;
			padding-bottom:100%;
			background-position: center;
			background-repeat: no-repeat;
			background-size: 90%;
			border: 1px solid #E9E9E9;
			cursor: pointer;
		}
		@include e (info){
			width: 60%;
			padding-left:30px;
		}
	}
}

@media only screen and (min-width: 992px) {
	.productPost{
		@include e (photoWrap){
			width: 35%;
			height: 35%;
		}
		@include e (infoTable){
			margin-bottom:60px;
		}
		@include e (info){
			width: 65%;
			padding-left:60px;
		}
	}
	.content-article{
		margin-bottom:60px;
	}
}