$tran:transparent;

$white:#fff;

$black:#000;
$black1:#323237;

$pink: #E40080;


$z_Header: 999;
$z-menuBg: 9999;