@import '../../Vendor/style/base/base';

/*=====  search  ===== */
.search{
	@include e (wrap){
    padding:0 20px;
    .ant-pagination{
      padding:20px 0 40px;
    }
	}
	@include e (header){
		padding-bottom: 20px;
	}
	@include e (info){
		font-size: 1.2rem;
		letter-spacing: 2.4px;
		line-height: 26px;
		margin:0;
	}
	@include e (infoNum){
		margin-left:5px;
	}
	@include e (box){
		display: flex;
		width:100%;
		margin-bottom: 20px;
		opacity: 0;
	}
	@include e (thumbnail){
		display: block;
		width:150px;
	}
	@include e (photo){
		width:100%;
		height: 0;
		padding-bottom:85px;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}
	@include e (boxInfo){
		width:calc(100% - 150px);
		padding-left:15px;
	}
	@include e (boxTitle){
		font-size: 1.5rem;
		font-weight: bold;
		letter-spacing: 3px;
		line-height: 26px;
		margin:0 0 14px;
		height: 55px;
	}
	@include e (summary){
		display: none;
	}
	@include e (time){
		font-size: 1rem;
		letter-spacing: 3px;
		line-height: 15px;
		margin:0;
	}
}

@media only screen and (min-width: 768px) {
	/*=====  search  ===== */
	.search{
    @include e (wrap){
      position: relative;
      .ant-pagination{
        padding:40px 0 80px;
      }
    }
		@include e (header){
			display: flex;
			align-items:baseline;
			padding-bottom: 25px;
		}
		@include e (info){
			font-size: 1.2rem;
			letter-spacing: 2.4px;
			line-height: 26px;
			margin-left:30px;
		}
		@include e (wrap){
			padding:25px 40px;
		}
		@include e (box){
			margin-bottom: 40px;
		}
		@include e (thumbnail){
			width:200px;
		}
		@include e (photo){
			padding-bottom:111px;
		}
		@include e (boxTitle){
			height: auto;
		}
		@include e (boxInfo){
			width:calc(100% - 200px);
			padding-left:20px;
		}
		@include e (summary){
			display: block;
			height: 50px;
			margin:0 0 4px;
			overflow: hidden;
			p{
				font-size: 1.2rem;
				letter-spacing: 2.4px;
				line-height: 26px;
				margin:0;
			}
		}
	}
}