@import '../../Vendor/style/base/base';
.content-row{
	.slick-dots{
		bottom:40px;
		left:-7.5px;
	}
}
.about{
	@include e (wrap){
		padding-top:20px;
	}
	@include e (hero){
		position: relative;
		margin-bottom:30px;
		font-size: 0;
		&::after{
			display: block;
			content: '';
			position: absolute;
			top:0;
			left:0;
			width:100%;
			height: 100%;
			background-color: rgba(0, 0, 0, .3);
		}
	}
	@include e (heroTitle){
		position: absolute;
		width:100%;
		top:calc(50% - 11px);
		font-size: 1.6rem;
		font-weight: normal;
		margin:0;
		letter-spacing: 2.4px;
		line-height: 23px;
		color: $white;
		text-align: center;
		z-index: 1;
	}
	@include e (content){
		font-size: 1.2rem;
		letter-spacing: 2.4px;
		line-height: 26px;
		text-align: center;
		max-width: 550px;
		margin:0 auto 15px;
	}
	@include e (title){
		font-size: 2rem;
		font-weight: 500;
		letter-spacing: 8.33px;
		line-height: 29px;
		text-align: center;
		margin-bottom:30px;
	}
	@include e (info){
		max-width: 550px;
		margin:0 auto 30px;
		.content-toknow{
			margin-top:25px;
		}
	}
	@include e (slider){
		margin-top:30px;
	}
}

@media only screen and (min-width: 992px) {
	.content-row{
		.slick-dots{
			bottom:60px;
			left:-7.5px;
		}
	}
	.about{
		@include e (content){
			margin:0 auto 60px;
		}
		@include e (heroTitle){
			top:calc(50% - 17px);
			font-size: 2.4rem;
			letter-spacing: 10px;
			line-height: 35px;
		}
		@include e (info){
			.content-toknow{
				margin-top:60px;
			}
		}
		@include e (slider){
			margin-top:60px;
		}
	}
}