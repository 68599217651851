@import '../../Vendor/style/base/base';

.Single {
  @include e (content){
    padding: 30px 20px 0;
  }
  @include e (leftBox){
    position: relative;
    margin-bottom: 30px;
    border-bottom: 1px solid #E9E9E9;
    @include m (social){
      display:flex;
      align-items:center;
    }
    @include m (article){
      display:flex;
      align-items:center;
      justify-content:center;
    }
    .Single-more{
      position: absolute;
      font-size: .9rem;
      font-weight: bold;
      letter-spacing: 2.7px;
      line-height: 13px;
      right:0;
      top:42px;
    }
  }
  @include e (nameTitle){
    color: #999999;
    font-size: 1.2rem;
    letter-spacing: 2.4px;
    line-height: 26px;
    margin:0 0 10px;
  }
  @include e (name){
    font-size: 1.5rem;
    font-weight: bold;
    letter-spacing: 3px;
    line-height: 26px;
    margin:0 0 30px;
  }
  @include e (description){
    display: none;
    font-size: 1.2rem;
    letter-spacing: 2.4px;
    line-height: 26px;
    margin:0 0 30px;
    &.active{
      display: block;
    }
  }
  @include e (article){
    font-size: 1.2rem;
    letter-spacing: 2.4px;
    line-height: 26px;
    margin-bottom:27px;
  }
  @include e (info){
    font-size: 1.2rem;
    letter-spacing: 2.4px;
    line-height: 26px;
    margin:0 0 20px;
  }
  @include e (btn){
    margin-bottom:10px;
    .btn{
      margin-right:10px;
    }
  }
}

@media only screen and (min-width: 992px) {
  .Single {
    @include e (content){
      padding: 60px 40px 0;
      @include m (project){
        display: flex;
      }
    }
    @include e (left){
      width:25%;
    }
    @include e (right){
      width:75%;
      margin-left: 80px;
    }
    @include e (leftBox){
      @include m (social){
        display:block;
        border:0;
        .ShareList-social{
          margin: 0 -5px;
        }
      }
      @include m (article){
        .ShareList-social, .content-shareTitle{
          margin-bottom: 60px;
        }
      }
      .content-description{
        display: block;
      }
      .Single-more{
        display: none;
      }
    }
    @include e (description){
      display: block;
    }
    @include e (article){
      margin-bottom:57px;
    }
    @include e (infoWrap){
      padding-top:30px;
    }
  }
}