@import '../../Vendor/style/base/base';
.menu{
	@include e (bg){
		position: fixed;
		top:0;
		left:0;
		width:100vw;
		height: 100vh;
		background-color: rgba(229, 0, 130, .7);
		z-index: $z-menuBg;
		opacity: 0;
		pointer-events: none;
		transition: opacity .75s;
		.validate{
			width:100%!important;
			height: 100%!important;
		}
		&.active{
			opacity: 1!important;
			pointer-events: auto!important;
		}
		&.j-menu{
			.menu-nav{
				display: block;
			}
		}
		&.j-order{
			.menu-order{
				display: block;
			}
		}
		&.j-search{
			.menu-search{
				display: block;
			}
		}
	}
	@include e (close){
		position: absolute;
		top:23px;
		right:23px;
		width:50px;
		height: 50px;
		padding:10px;
	}
	@include e (wrap){
		display: flex;
		width:100%;
		height: 100%;
		justify-content:center;
		align-items:center;
	}
	@include e (nav){
		display: none;
		width: 300px;
	}
	@include e (lists){
		list-style: none;
		font-size: 1.4rem;
		letter-spacing: 4px;
		line-height: 20px;
		margin:0;
		padding:0;
		>li{
			margin-bottom:20px;
		}
	}
	@include e (link){
		color:#fff;
		text-decoration: none;
		@include link-hover{
			color:#fff;
		}
		@include m (unline){
			border-bottom:1px solid $pink;
		}
	}
	@include e (linkWrap){
		position: relative;
		&::after{
			position: absolute;
			display: block;
			content: "";
			top:8px;
			left:calc(100% + 30px);
			height: 0;
			width: 0;
			border-bottom:1px solid $white;
			transition: width .5s .3s;
		}
		&.active{
			&::after{
				width:50px;
				transition: width .3s;
			}
			.menu-lists1{
				pointer-events:auto;
				opacity: 1;
				transition: all .3s .5s;
			}
		}
	}
	@include e (lists1){
		position: absolute;
		left:calc(100% + 90px);
		top:0;
		list-style: none;
		font-size: 1.2rem;
		min-width: 500px;
		letter-spacing: 4px;
		line-height: 20px;
		margin:0;
		padding:0;
		opacity: 0;
		pointer-events: none;
		transition: all .5s;
		>li{
			margin-bottom:15px;
		}
	}
	@include e (order){
		display: none;
		width: 300px;
		.mc-field-group{
			padding:0!important;
			width:100%!important;
		}
		.mce_inline_error{
			color:$white!important;
			background-color: transparent!important;
		}
		input{
			border-radius: 0!important;
		}
		#mc-embedded-subscribe{
			width: 100%;
			margin:0;
		}
	}
	@include e (inputWrap){
		margin-bottom:15px;
		.input{
			margin-bottom:15px;
		}
	}
	@include e (search){
		display: none;
		width: 300px;
	}
	@include e (keyword){
		font-size: 1.4rem;
		font-weight: bold;
		letter-spacing: 4px;
		line-height: 20px;
		color:$white;
		border-bottom:1px solid $white;
		padding-bottom:10px;
		margin:0 0 10px;
	}
	@include e (tagWrap){
		display: flex;
		flex-wrap: wrap;
	}
	@include e (tag){
		width:50%;
		font-size: 1.2rem;
		font-weight: 500;
		letter-spacing: 4px;
		line-height: 17px;
		color:$white;
		text-decoration: none;
		margin-bottom:10px;
	}
}

@media only screen and (min-width: 992px) {
}