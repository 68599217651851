.ant{
  @include e (input){
    border-radius: 0;
    border-color:#323237;
    min-height: auto!important;
  }
  @include e (select-selection){
    border-radius: 0;
    border-color:#323237;
  }
  @include e (select-arrow){
    &::before{
      content:'\E606';
      color:#323237;
    }
  }
  @include e (pagination){
    text-align: center;
  }
  @include e (pagination-item){
    border:0!important;
    a{
      color:#999;
    }
    @include link-hover{
      a{
        color:$black1;
      }
    }
  }
  @include e (pagination-item-link){
    padding:3px 8px 5px;
    border:0!important;
  }
  @include e (pagination-prev){
    @include link-hover{
      .ant-pagination-item-link{
        &::after{
          color:$black1;
        }
      }
    }
  }
  @include e (pagination-next){
    @include link-hover{
      .ant-pagination-item-link{
        &::after{
          color:$black1;
        }
      }
    }
  }
  @include e (pagination-item-active){
    a{
      font-weight: bold;
      color:$black1;
    }
  }
}