.btn{
  display: inline-flex;
  justify-content:center;
  align-items:center;
  font-size: 1.4rem;
  padding:0 15px;
  height: 30px;
  color:$black1;
  border: 1px solid $black1;
  border-radius: 8px;
  background-color: transparent;
  cursor: pointer;
  text-decoration: none;
  @include m (form){
    width:100%;
    height: 40px!important;
    color:$pink!important;
    font-size: 1.6rem!important;
    font-weight: 500;
    letter-spacing: 3.43px;
    line-height: 24px!important;
    border-radius: 0;
    border: 1px solid $white!important;
    background-color: $white!important;
  }
}